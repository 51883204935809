<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogExercise"
        persistent
        max-width="1400px"
        scrollable
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_exercise === 'add'" class="headline"
                >Thêm bài tập (Exercise)</span
              >
              <span v-if="type_form_exercise === 'update'" class="headline"
                >Cập nhật bài tập (Exercise)</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogExercise = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <label class="text-h5">Tiêu đề</label>
                  <v-text-field
                    filled
                    v-model="inputTitleExercise"
                    required
                  ></v-text-field>
                </v-col>
                <template v-if="canInputNarrator()">
                  <v-col cols="12" class="pb-0">
                    <label class="text-h5">Narrator</label>
                    <v-text-field
                        filled
                        v-model="narrator_input"
                    ></v-text-field>
                  </v-col>
                </template>
                <v-col cols="12" class="pt-0">
                  <label class="text-h5">Label</label>
                  <v-autocomplete
                      :items="prep_labels"
                      item-value="id"
                      item-text="content"
                      dense outlined clearable
                      v-model="labelIdInput"
                  />
                </v-col>
                <v-col cols="12" class="pt-0">
                  <label class="text-h5">Mô tả</label>
                  <Editor v-model="inputDesExercise"></Editor>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-switch
                    v-model="toggle_countdown_time"
                    :label="
                      'Thêm countdown time: ' +
                      (toggle_countdown_time ? 'Yes' : 'No')
                    "
                  ></v-switch>
                </v-col>
                <v-col cols="6" class="pb-0 pt-7">
                  <div v-if="toggle_countdown_time">
                    <label class="text-h6"
                      >Nhập countdown time (Phút:giây):
                    </label>
                    <date-picker
                      v-model="countdown_time_input"
                      type="time"
                      format="mm:ss"
                      value-type="format"
                      placeholder="Phút:giây"
                    ></date-picker>
                  </div>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-switch
                      class="mt-0 pt-0"
                      dense
                      v-model="toggle_interactive_video"
                      label="Interactive Exercise"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="6" class="pb-0">
                  <v-switch
                      class="mt-0 pt-0"
                      dense
                      v-model="has_config_instant_feedback"
                      label="Instant Feedback Exercises"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="6" class="pb-0" v-if="canConfigInputPinyin()">
                  <v-switch
                      class="mt-0 pt-0"
                      dense
                      v-model="has_phonetic_display"
                      label="Cho phép học sinh bật/ tắt hiển thị Pinyin"
                  ></v-switch>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="6" class="pt-0">
                  <v-autocomplete
                    :items="dataExerciseTypeFilter"
                    label="Chọn loại bài tập"
                    item-text="label"
                    item-value="value"
                    required filled
                    v-model="data_type_question"
                  ></v-autocomplete>
                  <p class="red--text" v-if="getExerciseTypeNote()">{{getExerciseTypeNote()}}</p>
                </v-col>
                <template v-if="data_type_question === exercise_type_constant.MATCHING ">
                  <Matching
                    :has-config-phonetic="allowedToUsePhoneticForExplanation()"
                    :correct-matching-data-prop="correctMatching"
                    :option-matching-data-prop="optionMatching"
                    :type_form_exercise="type_form_exercise"
                    @setOptionMatching="(e) => (this.optionMatching = e)"
                    @setCorrectMatching="(e) => (this.correctMatching = e)"
                  />
                </template>
                <template v-if="data_type_question === exercise_type_constant.ANSWER_IN_ANY_ORDER">
                  <AnswerInAnyOrder
                    :has-config-phonetic="allowedToUsePhoneticForExplanation()"
                    :option-list-selection-data-prop="optionListSelection"
                    @setOptionListSelection="(e) => (this.optionListSelection = e)"
                  />
                </template>
                <template v-if="data_type_question === exercise_type_constant.SHORT_ANSWER">
                  <v-col cols="3" class="pt-0">
                    <v-text-field
                      label="Max words"
                      required
                      type="number"
                      v-model="max_words"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <label style="font-size: 16px">Question</label>
                    <v-textarea
                      placeholder="Enter question here..."
                      class="pt-2 pl-2"
                      rows="8"
                      auto-grow
                      no-resize
                      v-model.trim="correctShortAnswer"
                      solo
                      ref="refShortAnswer"
                    ></v-textarea>
                  </v-col>
                  <v-col class="pt-0">
                    <v-btn
                      class="btn-add-file"
                      depressed
                      outlined
                      large
                      @click="openDialogShortAnswer"
                    >
                      <v-icon left>mdi-plus</v-icon> Add Answer
                    </v-btn>
                  </v-col>
                </template>
                <template v-if="data_type_question === exercise_type_constant.SINGLE_CHOICE_IN_PARAGRAPH">
                  <SingleChoiceInParagraph></SingleChoiceInParagraph>
                </template>
                <template v-if="data_type_question === exercise_type_constant.MATCHING_PAIR">
                  <MatchingPair
                    :has-config-phonetic="allowedToUsePhoneticForExplanation()"
                  />
                </template>
                <template v-if="data_type_question === exercise_type_constant.MOCK_CONVERSATION">
                  <MockConversation />
                </template>
                <template v-if="data_type_question === exercise_type_constant.VIRTUAL_CONVERSATION">
                  <v-col cols="12" class="m-0 p-0"></v-col>
                  <v-col cols="6" class="pb-0">
                    <label class="text-h5">Chọn sub-situation code</label>
                    <v-autocomplete
                        placeholder="Chọn sub-situation code"
                        filled dense small-chips
                        :items="list_sub_situation_code"
                        v-model="subSituationCodeInput"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <label class="text-h5">Mẫu câu gợi ý <span class="red--text subtitle-1">(Thông tin hiển thị trên giao diện)</span></label>
                    <Editor v-model="explanationExercise"></Editor>
                  </v-col>
                  <v-col cols="6" class="pb-0 mt-2" v-if="canInputRelatedUnitId()">
                    <label class="text-h5">Chọn các lesson cần học (thuộc topic đã chọn ở trước)</label>
                    <v-select
                        placeholder="Chọn lesson"
                        filled
                        :items="relatedUnits"
                        item-value="id"
                        item-text="test.title"
                        multiple chips
                        v-model="listRelatedUnitIdInput"
                    ></v-select>
                  </v-col>
                </template>
                <v-col cols="12" class="pb-0" v-if="canInputExplanation()">
                  <template v-if="allowedToUsePhoneticForExplanation()">
                    <PhoneticInput v-model="explanationExercise" label="Explanation" name-input-ref="inputExplanationExercise" />
                  </template>
                  <template v-else>
                    <label style="font-size: 16px">Explanation</label>
                    <Editor v-model="explanationExercise"></Editor>
                  </template>
                </v-col>

                <template
                  v-if="[
                      exercise_type_constant.SINGLE_CHOICE,
                      exercise_type_constant.SINGLE_CHOICE_EDITOR,
                      exercise_type_constant.SINGLE_SELECT,
                  ].includes(data_type_question)"
                >
                  <v-col cols="3" class="pt-0">
                    <v-switch
                      v-model="is_use_suggestion_order"
                      :label="`Gợi ý sắp xếp câu hỏi: ${is_use_suggestion_order.toString()}`"
                    ></v-switch>
                  </v-col>
                  <v-col
                    cols="12"
                    class="pb-0 pt-0"
                    v-if="is_use_suggestion_order"
                  >
                    <label style="font-size: 16px">Lí do gợi ý sắp xếp</label>
                    <v-textarea
                      placeholder="Nhập ...."
                      class="pt-2"
                      rows="4"
                      auto-grow
                      no-resize
                      v-model.trim="suggestion_order_comment"
                      dense
                      solo
                    ></v-textarea>
                  </v-col>
                </template>

                <v-col cols="12" v-if=" toggle_interactive_video &&
                  [
                    exercise_type_constant.MATCHING,
                    exercise_type_constant.MULTIPLE_CHOICE,
                    exercise_type_constant.SINGLE_CHOICE,
                  ].includes(data_type_question)">
                  <ConfigVimeo :dialogExercise="dialogExercise" />
                </v-col>

                <v-col cols="12">
                  <v-switch
                      class="mt-0 pt-0"
                    dense
                    v-model="toggle_element"
                    :label="`Thêm element: ${toggle_element.toString()}`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" v-if="toggle_element">
                  <ElementChoose
                      :has-access-config-flashcard="true"
                      :has-input-description-image="checkCanInputDesImage()"
                      :has-config-get-description-image-by-ai="checkCanUseGetDesImageByAI()"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogExercise = false"
              >Đóng</v-btn
            >
            <v-btn
              class="font-weight-bold"
              outlined
              color="blue darken-1"
              text
              v-if="type_form_exercise === 'add'"
              @click="btnAddExercise"
              >Thêm bài tập</v-btn
            >
            <v-btn
              class="font-weight-bold"
              outlined
              color="blue darken-1"
              text
              v-if="type_form_exercise === 'update'"
              @click="btnEditExercise"
              >Cập nhật bài tập</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import { mapState, mapGetters } from "vuex";
import SingleChoiceInParagraph from "@/view/pages/tests/Modal/ExerciseType/SingleChoiceInParagraph";
import ConfigVimeo from "./ConfigInteractiveVideo/ConfigVimeo";
import filterTimestamp from "./ConfigInteractiveVideo/filterTimestamp";
import configPart from "./Support/Section/configPart";
import MatchingPair from "./ExerciseType/MatchingPair";
import productCategorySupport from "./Support/Test/productCategorySupport";
import constantTestBuilder from "../mixins/Support/constantTestBuilder";
import MockConversation from "./ExerciseType/MockConversation";
import labelSupport from "./Support/Test/labelSupport";
import fileSupport from "./Support/fileSupport";
import PhoneticInput from "./Support/Question/PhoneticInput";
import Matching from "./ExerciseType/Matching";
import elementSupport from "./Support/Element/elementSupport";
export default {
  name: "ExerciseModal",
  mixins: [
      filterTimestamp,
      configPart,
      productCategorySupport,
      constantTestBuilder,
      labelSupport,
      fileSupport,
      elementSupport,
  ],
  components: {
    Matching,
    MockConversation,
    MatchingPair,
    ConfigVimeo,
    SingleChoiceInParagraph,
    ElementChoose: () => import("./ElementChoose"),
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
    PhoneticInput,
    AnswerInAnyOrder: () => import("./ExerciseType/AnswerInAnyOrder"),
  },
  data() {
    return {
      max_words: null,
      inputTitleExercise: null,
      inputDesExercise: null,
      data_type_question: null,
      toggle_element: false,
      optionMatching: [{ value: ""}, { value: ""}],
      correctMatching: [{ value: "", index: "" }],
      optionListSelection: [{ value: "", checked: false }],
      questions: [],
      offsetSortExercise: 0,
      explanationExercise: "",
      toggle_countdown_time: false,
      countdown_time_input: null,
      is_use_suggestion_order: false,
      suggestion_order_comment: "",
      // toggle_interactive_video: false,
      skillCurrent: null,
      narrator_input: null,
      limit_time_question: null,
      has_config_instant_feedback: false,
      has_phonetic_display: false,
      subSituationCodeInput: null,
      listRelatedUnitIdInput:[],
      relatedUnits:[],
      prep_labels: [],
      labelIdInput: null,
    };
  },
  computed: {
    dataExerciseType: function () {
      let vm = this;
      let dataClone = JSON.parse(JSON.stringify(this.exercise_types));
      dataClone = dataClone.map((exercise_type_obj) => {
        exercise_type_obj.show = !vm.listExerciseTypeNotSupportForQuestionNormal().includes(exercise_type_obj.value);
        return exercise_type_obj;
      });
      return dataClone;
    },
    dataExerciseTypeFilter: function () {
      return this.dataExerciseType.filter(e => e.show);
    },
    ...mapState({
      type_form_exercise: (state) =>
        state.contentTestBuilderStore.type_form_exercise,
      test_id: (state) => state.informationTestBuilderStore.test_id,
      skill_id: (state) => state.contentTestBuilderStore.skill_id,
      section_id: (state) => state.contentTestBuilderStore.section_id,
      exercise_id: (state) => state.contentTestBuilderStore.exercise_id,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      indexExercise: (state) => state.contentTestBuilderStore.indexExercise,
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
      type_expand: (state) => state.informationTestBuilderStore.type_expand,
      topic_id: (state) => state.informationTestBuilderStore.topic_id,
      list_situation_code: (state) => state.informationTestBuilderStore.list_situation_code,
      list_sub_situation_code: (state) => state.informationTestBuilderStore.list_sub_situation_code,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogExercise: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogExercise;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDialogExercise", value);
      },
    },
    type_element: {
      get() {
        return this.$store.state.elementStore.type_element;
      },
      set(value) {
        this.$store.commit("elementStore/setTypeElement", value);
      },
    },
    element_post_data: {
      get() {
        return this.$store.state.elementStore.element_post_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPostData", value);
      },
    },
    element_audio_data: {
      get() {
        return this.$store.state.elementStore.element_audio_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementAudioData",
          value
        );
      },
    },
    element_image_data: {
      get() {
        return this.$store.state.elementStore.element_image_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementImageData",
          value
        );
      },
    },
    element_audio_file: {
      get() {
        return this.$store.state.elementStore.element_audio_file;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementAudioFile",
          value
        );
      },
    },
    element_image_file: {
      get() {
        return this.$store.state.elementStore.element_image_file;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementImageFile",
          value
        );
      },
    },
    element_id: {
      get() {
        return this.$store.state.elementStore.element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setElementId", value);
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.elementStore.element_form_display;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setElementFormDisplay",
          value
        );
      },
    },
    video_element_id: {
      get() {
        return this.$store.state.elementStore.video_element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setVideoElementId", value);
      },
    },
    video_element_data: {
      get() {
        return this.$store.state.elementStore.video_element_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setVideoElementData",
          value
        );
      },
    },
    optionShortAnswer: {
      get() {
        return this.$store.state.contentTestBuilderStore.optionShortAnswer;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setOptionShortAnswer",
          value
        );
      },
    },
    dialogShortAnswer: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogShortAnswer;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogShortAnswer",
          value
        );
      },
    },
    correctShortAnswer: {
      get() {
        return this.$store.state.contentTestBuilderStore.correctShortAnswer;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setCorrectShortAnswer",
          value
        );
      },
    },
    refShortAnswer: {
      get() {
        return this.$store.state.contentTestBuilderStore.refShortAnswer;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setRefShortAnswer", value);
      },
    },
    album_element_id: {
      get() {
        return this.$store.state.elementStore.album_element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setAlbumElementId", value);
      },
    },
    album_element_data: {
      get() {
        return this.$store.state.elementStore.album_element_data;
      },
      set(value) {
        this.$store.commit(
          "elementStore/setAlbumElementData",
          value
        );
      },
    },
    contentSingleChoiceInParagraph: {
      get() {
        return this.$store.state.contentTestBuilderStore
          .contentSingleChoiceInParagraph;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setContentSingleChoiceInParagraph",
          value
        );
      },
    },
    dataCorrectSingleChoiceParagraph: {
      get() {
        return this.$store.state.contentTestBuilderStore
          .dataCorrectSingleChoiceParagraph;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDataCorrectSingleChoiceParagraph",
          value
        );
      },
    },
    element_pdf_file: {
      get() {
        return this.$store.state.elementStore.element_pdf_file;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPdfFile", value);
      },
    },
    element_pdf_data: {
      get() {
        return this.$store.state.elementStore.element_pdf_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPdfData", value);
      },
    },
    element_youtube_data: {
      get() {
        return this.$store.state.elementStore.element_youtube_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementYoutube", value);
      },
    },
    type_expand: {
      get() {
        return this.$store.state.informationTestBuilderStore.type_expand;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setTypeExpand", value);
      },
    },
    display_timestamp_input: {
      get() {
        return this.$store.state.interactiveVideoStore.display_timestamp;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setDisplayTimestamp", value);
      },
    },
    display_explanation_input: {
      get() {
        return this.$store.state.interactiveVideoStore.display_explanation;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setDisplayExplanation", value);
      },
    },
    continue_lesson_timestamp_input: {
      get() {
        return this.$store.state.interactiveVideoStore.continue_lesson_timestamp;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setContinueLessonTimestamp", value);
      },
    },
    waiting_time_input: {
      get() {
        return this.$store.state.interactiveVideoStore.waiting_time;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setWaitingTime", value);
      },
    },
    time_limit_input: {
      get() {
        return this.$store.state.interactiveVideoStore.time_limit;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setTimeLimit", value);
      },
    },
    acceptable_score_input: {
      get() {
        return this.$store.state.interactiveVideoStore.acceptable_score;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setAcceptableScore", value);
      },
    },
    toggle_interactive_video: {
      get() {
        return this.$store.state.interactiveVideoStore.toggle_interactive_video;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setToggleInteractiveVideo", value);
      },
    },
    url_video_config: {
      get() {
        return this.$store.state.interactiveVideoStore.url_video_config;
      },
      set(value) {
        this.$store.commit("interactiveVideoStore/setUrlVideoConfig", value);
      },
    },
    element_description: {
      get() {
        return this.$store.state.elementStore.element_description;
      },
      set(value) {
        this.$store.commit("elementStore/setElementDescription", value);
      },
    },
    data_question_matching_pair_input: {
      get() {
        return this.$store.state.contentTestBuilderStore.data_question_matching_pair_input;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDataQuestionMatchingPairInput", value);
      },
    },
    matching_pair_question_type: {
      get() {
        return this.$store.state.contentTestBuilderStore.matching_pair_question_type;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setMatchingPairQuestionType", value);
      },
    },
    data_option_matching_pair_input: {
      get() {
        return this.$store.state.contentTestBuilderStore.data_option_matching_pair_input;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDataOptionMatchingPairInput", value);
      },
    },
    matching_pair_option_type: {
      get() {
        return this.$store.state.contentTestBuilderStore.matching_pair_option_type;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setMatchingPairOptionType", value);
      },
    },
    data_correct_matching_pair: {
      get() {
        return this.$store.state.contentTestBuilderStore.data_correct_matching_pair;
      },
      set(value) {
        this.$store.commit("contentTestBuilderStore/setDataCorrectMatchingPair", value);
      },
    },
    dataMockConversationQuestion: {
      get() {
        return this.$store.state.exerciseConfigStore.dataMockConversationQuestion;
      },
      set(value) {
        this.$store.commit("exerciseConfigStore/setDataMockConversationQuestion", value);
      },
    },
    element_flashcards: {
      get() {
        return this.$store.state.elementStore.element_flashcards;
      },
      set(value) {
        this.$store.commit("elementStore/setElementFlashcards", value);
      },
    },
    element_gif_file: {
      get() {
        return this.$store.state.elementStore.element_gif_file;
      },
      set(value) {
        this.$store.commit("elementStore/setElementGifFile", value);
      },
    },
    element_gif_data: {
      get() {
        return this.$store.state.elementStore.element_gif_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementGifData", value);
      },
    },
  },
  watch: {
    dialogExercise: function (val) {
      if (val) {
        this.setDataInput(this.type_form_exercise);
        if (this.type_form_exercise === 'add') {
          let validateExerLimit = this.validateExerciseLimit();
          if (!validateExerLimit) {
            this.$nextTick(() => {
              this.dialogExercise = false;
            })
          }
          if (!this.canOpenCreateDialogExercise()) {
            this.$nextTick(() => {
              this.dialogExercise = false;
            })
          }
        }
      } else {
        this.resetDataInteractiveVideo();
      }
    },
    toggle_interactive_video(val) {
      if (val) {
        let vm = this;
        let validator =  this.validatorConfigInteractiveVideo();
        if (validator) {
          vm.$nextTick(function() {
            vm.toggle_interactive_video = false;
          })
        }
        let section = this.skills[this.indexSkill].sections[this.indexSection];
        if (section.element_type === 'Video') this.url_video_config = section.element_data;

        // filter option question_type for interactive video
        for (let i = 0; i <this.dataExerciseType.length ; i++) {
          if (!this.isTypeQuestionForInteractiveVideo(this.dataExerciseType[i].value)) {
            this.dataExerciseType[i].show = false;
          }
        }
        // end filter option question_type for interactive video
      } else {
        this.resetDefaultOptionExType();
      }
    },
    has_config_instant_feedback(val) {
      if (val) {
        let vm = this;
        let validator =  this.canToggleConfigInstantFeedback();
        if (validator) {
          vm.$nextTick(function() {
            vm.has_config_instant_feedback = false;
          })
        }
        // for (let i = 0; i <this.dataExerciseType.length ; i++) {
        //   if (!this.isTypeQuestionForInstantFeedback(this.dataExerciseType[i].value)) {
        //     this.dataExerciseType[i].show = false;
        //   }
        // }
      }
      // else {
      //   this.resetDefaultOptionExType();
      // }
    },
    has_phonetic_display(val) {
      if (val) {
        let vm = this;
        let validator =  this.canToggleConfigInputPinyin();
        if (validator) {
          vm.$nextTick(function() {
            vm.has_phonetic_display = false;
          })
        }
      }
      // else {
      //   this.resetDefaultOptionExType();
      // }
    },
    data_type_question(val) {
      let vm = this;
      if (!this.isTypeQuestionForInteractiveVideo(val)) {
        vm.$nextTick(function() {
          vm.toggle_interactive_video = false;
        })
      }
      if (!this.isTypeQuestionForInstantFeedback(val)) {
        vm.$nextTick(function() {
          vm.has_config_instant_feedback = false;
        })
      }
      this.getRelatedUnitsByTopic();
    }
  },
  mounted() {

  },
  methods: {
    setDataInput(type) {
      this.skillCurrent = this.skills[this.indexSkill].skill_type;
      this.setDisplayTypeExercise();
      // cụm matching pair ( mặc định )
      this.defaultDataMatchingPair();
      // -- end matching pair --
      this.defaultDataMockConversation();
      this.data_type_question = null;
      this.prep_labels = this.setPrepLabel();
      if (type === "add") {
        this.max_words = null;
        this.inputTitleExercise = null;
        this.inputDesExercise = "";
        if (this.skillCurrent === 'Speaking' && this.skills[this.indexSkill].vsr === 1) {
          this.data_type_question = this.exercise_type_constant.RECORDING;
        }
        if (this.skillCurrent === 'Writing' && this.skills[this.indexSkill].vwr === 1) {
          if(this.skills[this.indexSkill].sections[this.indexSection].part === 6) {
            this.limit_time_question = "08:00";
          } else {
            this.limit_time_question = "00:00";
          }
        }
        this.type_element = null;
        this.element_post_data = null;
        this.element_image_data = null;
        this.element_audio_data = null;
        this.element_audio_file = null;
        this.element_image_file = null;
        this.element_description = null;
        this.element_id = null;
        this.video_element_data = null;
        this.video_element_id = null;
        this.toggle_element = false;
        this.optionMatching = [{ value: ""}, { value: ""}, { value: ""}];
        this.correctMatching = [{ value: "", index: "" }];
        this.optionListSelection = [
          { value: "", checked: false },
          { value: "", checked: false },
          { value: "", checked: false },
        ];
        this.correctShortAnswer = null;
        this.explanationExercise = "";
        this.offsetSortExercise = this.skills[this.indexSkill].sections[this.indexSection].exercises.length + 1;
        this.album_element_id = null;
        this.album_element_data = [];
        this.contentSingleChoiceInParagraph = "";
        this.dataCorrectSingleChoiceParagraph = [];
        this.element_pdf_file = null;
        this.element_pdf_data = null;
        this.toggle_countdown_time = false;
        this.countdown_time_input = null;
        this.is_use_suggestion_order = false;
        this.suggestion_order_comment = "";
        this.element_youtube_data = null;
        this.toggle_interactive_video = false;
        this.narrator_input = null;
        this.has_config_instant_feedback = false;
        this.has_phonetic_display = false;
        this.listRelatedUnitIdInput = [];
        this.subSituationCodeInput = null;
        this.labelIdInput = null;
        this.element_flashcards = this.getDataDefaultElementFlashcard();
        this.element_gif_data = null;
        this.element_gif_file = null;
      } else {
        let data = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise];
        data = JSON.parse(JSON.stringify(data));
        this.inputTitleExercise = data.exercise_title;
        this.inputDesExercise = data.exercise_description == null ? "" : data.exercise_description;
        this.data_type_question = parseInt(data.exercise_type);
        this.type_element = data.element_type;
        this.element_post_data = data.element_type === "Post" ? data.element_data : "";
        this.element_image_data = data.element_type === "Image" ? data.element_data : null;
        this.element_audio_data = data.element_type === "Audio" ? data.element_data : null;
        this.video_element_data = data.element_type === "Video" ? data.element_data : null;
        this.element_pdf_data = data.element_type === "Pdf" ? data.element_data : null;
        this.element_youtube_data = data.element_type === "Embed_yt" ? data.element_data : null;
        this.element_gif_data = data.element_type === "Gif" ? data.element_data : null;
        this.element_audio_file = null;
        this.element_pdf_file = null;
        this.element_image_file = null;
        this.element_gif_file = null;
        this.element_description = data.element_description ?? '';
        this.element_id = this.checkElementId(data.element_type, data.element_id);
        this.video_element_id = data.element_type === "Video" ? data.element_id : null;
        this.album_element_id = data.element_type === "Album" ? data.element_id : null;
        this.album_element_data = data.element_type === "Album" ? data.element_data : [];
        if (data.element_type === "Flashcard") {
          this.element_flashcards = data.element_data.map((item) => {
            item.example = item.example ? item.example : '';
            item.audio_data = item.audio_path;
            item.audio_file = null;
            item.image_data = item.image_path;
            item.image_file = null;
            return item;
          })
        } else {
          this.element_flashcards = this.getDataDefaultElementFlashcard();
        }
        this.toggle_element = data.is_element;
        this.questions = data.questions;
        // console.log(data.body)
        if (data.exercise_type === this.exercise_type_constant.MATCHING) {
          this.correctMatching = [...data.body.correct];
          this.optionMatching = [...data.body.option].map((item) => {
            return { value: item };
          });
        } else if (data.exercise_type === this.exercise_type_constant.ANSWER_IN_ANY_ORDER) {
          this.optionListSelection = [...data.body];
        } else if (data.exercise_type === this.exercise_type_constant.SHORT_ANSWER) {
          this.correctShortAnswer = data.body;
        } else if (data.exercise_type === this.exercise_type_constant.SINGLE_CHOICE_IN_PARAGRAPH) {
          this.contentSingleChoiceInParagraph = data.body.content;
          this.dataCorrectSingleChoiceParagraph = JSON.parse(
            JSON.stringify(data.body.data_correct_option)
          );
        } else if (data.exercise_type === this.exercise_type_constant.MATCHING_PAIR) {
          let bodyMatchingPair = JSON.parse(JSON.stringify(data.body));
          this.data_question_matching_pair_input = bodyMatchingPair.questions;
          this.data_option_matching_pair_input = bodyMatchingPair.options;
          this.matching_pair_question_type = bodyMatchingPair.question_type;
          this.matching_pair_option_type = bodyMatchingPair.option_type;
          this.data_correct_matching_pair = bodyMatchingPair.list_correct
        } else if (data.exercise_type === this.exercise_type_constant.MOCK_CONVERSATION) {
          this.dataMockConversationQuestion = JSON.parse(JSON.stringify(data.body.dataMockConversationQuestion));
        }
        this.max_words = this.checkDisplayValidationRules(data);
        this.explanationExercise = data.explanation == null ? "" : data.explanation;
        this.toggle_countdown_time = data.countdown_time != null;
        this.is_use_suggestion_order = data.is_use_suggestion_order === 1;
        this.suggestion_order_comment = data.suggestion_order_comment;
        this.countdown_time_input = data.countdown_time ? this.$options.filters.convertSecondToMS(data.countdown_time) : null;
        this.toggle_interactive_video = data.interactive == 1;

        if (data.interactive_config) {
          let data_config = data.interactive_config;
          this.display_timestamp_input = data_config.display_timestamp;
          this.display_explanation_input = data_config.display_explanation;
          this.continue_lesson_timestamp_input = data_config.continue_lesson_timestamp;
          this.waiting_time_input = data_config.waiting_time;
          this.time_limit_input = data_config.time_limit;
          this.acceptable_score_input = data_config.acceptable_score;
        }
        this.narrator_input = data.narrator;
        // this.limit_time_question = this.checkDisplayValidationRules(data);
        this.has_config_instant_feedback = data.instant_feedback == 1;
        this.has_phonetic_display = data.phonetic_display == 1;
        this.listRelatedUnitIdInput = data.related_unit_ids ?? [];
        this.subSituationCodeInput = data.situation_code ?? null;
        this.labelIdInput = data.label_id ?? null;
      }
    },
    checkElementId(type, element_id) {
      let check_element_id = element_id;
      if (type === "Video" || type === "Album") {
        check_element_id = null;
      }
      return check_element_id;
    },
    checkDisplayValidationRules(exercise) {
      let data = null;
      if (exercise.validation_rules != null) {
        let validation_rules = exercise.validation_rules;
        // if (exercise.exercise_type === "Short Answer") {
        if (validation_rules.max_words) {
          data = validation_rules.max_words;
        }
        if (validation_rules.limit_time_question) {
          data = validation_rules.limit_time_question;
        }
      }
      return data;
    },
    validateQuestionByTypeForExercise(type) {
      let flat = true;
      let vm = this;
      if (type === this.exercise_type_constant.MATCHING) {
        for (let i = 0; i < this.optionMatching.length; i++) {
          if (this.$utils.isValueInvalid(this.optionMatching[i].value)) {
            vm.messError("Hãy nhập đủ thông tin cho option " + (i + 1) + " !!");
            flat = false;
          }
        }
        for (let i = 0; i < this.correctMatching.length; i++) {
          if (this.correctMatching[i].value === "") {
            vm.messError("Hãy điền thông tin cho question " + (i + 1) + " !!");
            flat = false;
          }
          if (this.correctMatching[i].index === "") {
            vm.messError("Hãy chọn option cho question " + (i + 1) + " !!");
            flat = false;
          }
        }
      }
      if (type === this.exercise_type_constant.ANSWER_IN_ANY_ORDER) {
        for (let i = 0; i < this.optionListSelection.length; i++) {
          if (this.optionListSelection[i].value === "") {
            vm.messError("Hãy nhập thông tin cho option " + (i + 1) + " !!");
            flat = false;
          }
        }
      }
      if (type === this.exercise_type_constant.SHORT_ANSWER) {
        if (this.correctShortAnswer == null || this.correctShortAnswer === "") {
          vm.messError("Hãy nhập dữ liệu cho question");
          flat = false;
        } else {
          if ((this.correctShortAnswer.match(/{{(.*?)}}/g) || []).length <= 0) {
            vm.messError("Phải có tối thiểu 1 đáp án");
            flat = false;
          }
          let checkBracesOpen = (this.correctShortAnswer.match(/{{/g) || [])
              .length;
          let checkBracesClose = (this.correctShortAnswer.match(/}}/g) || [])
              .length;
          if (checkBracesOpen !== checkBracesClose) {
            vm.messError("Các dấu ngoặc giá trị (đóng, mở) trong đoạn văn chưa đủ !!");
            flat = false;
          }
        }
      }
      if (type === this.exercise_type_constant.SINGLE_CHOICE_IN_PARAGRAPH) {
        if (this.dataCorrectSingleChoiceParagraph.length <= 0) {
          vm.messError("Hãy nhập dữ liệu sau đó đồng bộ và chọn đáp án đúng");
          flat = false;
        } else {
          this.dataCorrectSingleChoiceParagraph.forEach(function (data, key) {
            if (data.correct === "" || data.correct == null) {
              vm.messError("Hãy chọn đáp án cho option " + (key + 1) + " !!");
              flat = false;
            }
          });
        }
      }
      if (this.type_expand === this.test_type_expand_constant.TEST) {
        if (type === this.exercise_type_constant.LONG_ANSWER) {
          if (this.skills[this.indexSkill].skill_type === "Speaking") {
            vm.messError("Không thể tạo dạng bài tập " + type + " trong kỹ năng Speaking");
            flat = false;
          }
        }
        if (type === this.exercise_type_constant.RECORDING) {
          if (this.skills[this.indexSkill].skill_type === "Writing") {
            vm.messError("Không thể tạo dạng bài tập " + type + " trong kỹ năng Writing");
            flat = false;
          }
        }
      }
      if (type === this.exercise_type_constant.MATCHING_PAIR) {
        if (this.data_question_matching_pair_input.length !== this.data_option_matching_pair_input.length) {
          vm.messError("Số lượng question và option không bằng nhau");
          flat = false;
        }
        for (let i = 0; i < this.data_question_matching_pair_input.length; i++) {
          if (!this.data_question_matching_pair_input[i].value) {
            vm.messError("Hãy nhập thông tin cho question " + (i + 1) + " !");
            flat = false;
          }
        }
        for (let i = 0; i < this.data_option_matching_pair_input.length; i++) {
          if (!this.data_option_matching_pair_input[i].value) {
            vm.messError("Hãy nhập thông tin cho option " + (i + 1) + " !");
            flat = false;
          }
        }
        for (let i = 0; i < this.data_correct_matching_pair.length; i++) {
          if (!this.data_correct_matching_pair[i].option.value) {
            vm.messError("chưa chọn đáp án question " + (i + 1) + " !");
            flat = false;
          }
        }

      }
      if (type === this.exercise_type_constant.MOCK_CONVERSATION) {
        if (!this.dataMockConversationQuestion.length) {
          vm.messError("Chưa nhập dữ liệu mock conversation");
          flat = false;
        } else {
          // get all and unique character_id
          let characterIds = this.dataMockConversationQuestion.map(item => item.character_id);
          let uniqueCharacterIds = [...new Set(characterIds)];
          if (uniqueCharacterIds.length && uniqueCharacterIds.length < 2) {
            vm.messError("Bạn phải nhập lời thoại của 2 nhân vật trở lên");
            flat = false;
          }
        }
        for (let iQues = 0; iQues < this.dataMockConversationQuestion.length; iQues++) {
          if (!this.dataMockConversationQuestion[iQues].character_id) {
            vm.messError("Chưa chọn nhân vật cho script #" + (iQues + 1));
            flat = false;
          }
          if (!this.dataMockConversationQuestion[iQues].script.trim()) {
            vm.messError("Chưa nhập lời thoại cho script #" + (iQues + 1));
            flat = false;
          }
          if (!this.dataMockConversationQuestion[iQues].translate_script.trim()) {
            vm.messError("Chưa nhập dịch lời thoại cho script #" + (iQues + 1));
            flat = false;
          }
          if (!this.dataMockConversationQuestion[iQues].audio_data) {
            vm.messError("Chưa nhập audio cho script #" + (iQues + 1));
            flat = false;
          }
          if (this.dataMockConversationQuestion[iQues].audio_file) {
            if (this.dataMockConversationQuestion[iQues].audio_file.size > 2097152) {
              vm.messError("File audio của script #" + (iQues + 1) + " không được lớn hơn 2MB");
              flat = false;
            }
            let extFile = this.$utils.getExtensionByFile(this.dataMockConversationQuestion[iQues].audio_file);
            if (!["mp3", "wav", "ogg",].includes(extFile)) {
              this.messError("Script #" + (iQues + 1) + ". File audio không đúng định dạng, chỉ bao gồm mp3, wav, ogg");
              flat = false;
            }
          }

        }
      }
      if (type === this.exercise_type_constant.VIRTUAL_CONVERSATION) {
        this.explanationExercise = this.explanationExercise ? this.explanationExercise.trim() : '';
        if (!this.explanationExercise) {
          vm.messError("Vui lòng nhập mẫu câu gợi ý cho bài");
          flat = false;
        }
        // if (this.listRelatedUnitIdInput.length <=0) {
        //   vm.messError("Vui lòng chọn lesson cần học");
        //   flat = false;
        // }
      }

      if (!canInputMockConversationInSection()) {
        vm.messError("Section này đã có bài tập khác hoặc đã có một bài tập dialogue shadowing. Vui lòng kiểm tra lại.");
        flat = false;
      }
      function canInputMockConversationInSection() {
        // hiện tại trong mô tả bussiness rule không có kiểm tra tầng skill nên sẽ bỏ qua: https://prepvn.atlassian.net/browse/LEARN-5842
        let mockConversationFlat = true;
        if (
            [vm.product_category_constant.PREPTALK_ENGLISH, vm.product_category_constant.PREPTALK_ENGLISH_V2].includes(vm.product_category_id) &&
            vm.type_expand === vm.test_type_expand_constant.LESSON
        ) {
          let exercises = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises;
          let totalDialogueShadowing = exercises.filter(exercise => exercise.exercise_type === vm.exercise_type_constant.MOCK_CONVERSATION).length;
          let totalOtherExercises = exercises.filter(exercise => exercise.exercise_type !== vm.exercise_type_constant.MOCK_CONVERSATION).length;
          if (vm.type_form_exercise === 'add') {
            if (totalDialogueShadowing >= 1) mockConversationFlat = false;
          }
          if (vm.type_form_exercise === 'update') {
            if (totalDialogueShadowing > 1) mockConversationFlat = false;
          }
          if (totalOtherExercises >= 1 && type === vm.exercise_type_constant.MOCK_CONVERSATION) mockConversationFlat = false;
          return mockConversationFlat;
        }
        return mockConversationFlat;
      }
      return flat;
    },
    validateElementExercise() {
      let flat = true;
      if (this.inputTitleExercise == null || this.inputTitleExercise === "") {
        this.$toasted.error("Hãy nhập tiêu đề cho exercise !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      if (this.data_type_question == null || this.data_type_question === "None") {
        this.$toasted.error("Bạn chưa chọn loại bài tập !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      if (this.toggle_element) {
        if (this.type_element == null) {
          this.$toasted.error("Hãy chọn loại element !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
        if (this.type_element === "Video") {
          if (this.video_element_data == null) {
            this.$toasted.error("Bạn chưa chọn tệp tin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.type_element === "Image") {
          if (this.element_image_data == null) {
            this.$toasted.error("Bạn chưa chọn tập tin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
          if (this.element_description) {
            if (this.element_description.replaceAll('\n', '').length > 3000) {
              this.$toasted.error("Mô tả ảnh không được nhập quá 3000 ký tự", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              flat = false;
            }
          }
        }
        if (this.type_element === "Audio") {
          if (this.element_audio_data == null) {
            this.$toasted.error("Bạn chưa chọn tệp tin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.type_element === "Post") {
          if (this.element_post_data == null) {
            this.$toasted.error("Bạn chưa nhập dữ liệu !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.type_element === "Embed_yt") {
          if (this.element_youtube_data == null || this.element_youtube_data === "") {
            this.$toasted.error("Bạn chưa nhập link youtube !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.type_element === "Flashcard") {
          if (this.element_flashcards.length <= 0) {
            this.messError("Bạn chưa nhập dữ liệu !!");
            flat = false;
          } else {
            for (let i = 0; i < this.element_flashcards.length; i++) {
              let flashcard = this.element_flashcards[i];
              if (this.$utils.isValueInvalid(flashcard.word)) {
                this.messError("Bạn chưa nhập từ ở card " + (i + 1));
                flat = false;
              }
              if (this.$utils.isValueInvalid(flashcard.meaning)) {
                this.messError("Bạn chưa nhập giải nghĩa ở card " + (i + 1));
                flat = false;
              }
            }
          }
        }
      }
      if (this.toggle_countdown_time) {
        if (this.countdown_time_input == null || this.countdown_time_input === "") {
          this.$toasted.error("Hãy nhập countdown time !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
        if (this.$options.filters.convertMSToSecond(this.countdown_time_input) <= 0) {
          this.$toasted.error("Countdown time phải lớn hơn 00:00 !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      }
      if (this.toggle_interactive_video) {
        if (this.display_timestamp_input && this.display_explanation_input && this.continue_lesson_timestamp_input) {
          if (!(this.$options.filters.convertHISUToSecond(this.display_timestamp_input) < this.$options.filters.convertHISUToSecond(this.display_explanation_input))) {
            this.$toasted.error("Timestamp hiển thị câu hỏi không được lớn hơn Timestamp giải thích đáp án", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
          if (!(this.$options.filters.convertHISUToSecond(this.display_explanation_input) < this.$options.filters.convertHISUToSecond(this.continue_lesson_timestamp_input))) {
            this.$toasted.error("Timestamp giải thích đáp án không được lớn hơn Timestamp hiển thị nội dung học tiếp theo", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }

        if (!this.display_timestamp_input) {
          this.$toasted.error("Chưa nhập mốc hiển thị câu hỏi", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        } else {
          if (!this.isFormatHISU(this.display_timestamp_input)) {
            this.$toasted.error("Mốc hiển thị câu hỏi nhập không đúng định dạng", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.display_explanation_input) {
          if (!this.isFormatHISU(this.display_timestamp_input)) {
            this.$toasted.error("Mốc hiển thị đáp án không đúng định dạng", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (!this.continue_lesson_timestamp_input) {
          this.$toasted.error("Chưa nhập mốc hiển thị nội dung học tiếp theo", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        } else {
          if (!this.isFormatHISU(this.continue_lesson_timestamp_input)) {
            this.$toasted.error("Mốc hiển thị nội dung học tiếp theo không đúng định dạng", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        // if (!this.waiting_time_input) {
        //   this.$toasted.error("Chưa nhập mốc hiển thị thời gian chờ", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        //   flat = false;
        // } else {
        //   if (!this.validateDecimalNumber(this.waiting_time_input)) {
        //     this.$toasted.error("Mốc hiển thị thời gian chờ không đúng định dạng", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        //     flat = false;
        //   }
        // }
        if (this.waiting_time_input){
          if (!this.validateDecimalNumber(this.waiting_time_input)) {
            this.$toasted.error("Mốc hiển thị thời gian chờ không đúng định dạng", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }

        if (!this.time_limit_input) {
          this.$toasted.error("Chưa nhập mốc thời gian làm bài", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        } else {
          if (!this.validateDecimalNumber(this.time_limit_input)) {
            this.$toasted.error("Mốc thời gian làm bài không đúng định dạng", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
      }
      if (this.toggle_interactive_video && this.has_config_instant_feedback) {
        this.$toasted.error("Không thể cấu hình Interactive và IFE trong cùng 1 Exercise ", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      let totalAllUseIFE = this.skills[this.indexSkill].sections[this.indexSection].exercises.filter((exercise) => exercise.instant_feedback == 1);
      let totalAllNotUseIFE = this.skills[this.indexSkill].sections[this.indexSection].exercises.filter((exercise) => exercise.instant_feedback == 0);
      if ( (this.has_config_instant_feedback && totalAllNotUseIFE.length > 0 ) || ( !this.has_config_instant_feedback && totalAllUseIFE.length > 0 )) {
        this.$toasted.error("Không thể cấu hình bài tập thường và IFE trong cùng 1 section", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }

      return flat;
    },
    validateExerciseLimit() {
      let flat = true;
      let currentSection = this.skills[this.indexSkill].sections[this.indexSection];
      if (currentSection.exercise_limit != null) {
        if (currentSection.exercises.length >= currentSection.exercise_limit) {
          this.$toasted.error("Mỗi phần trong bài thi "+this.skillCurrent+" "+this.getObjPCategoryById(this.product_category_id)?.slug.toUpperCase() + " chỉ được tạo tối đa "+currentSection.exercise_limit+" bài tập", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      }
      return flat;
    },
    async btnAddExercise() {
      let validateExerLimit = this.validateExerciseLimit();
      if (!validateExerLimit) {
        return;
      }
      let validateExer = this.validateElementExercise();
      if (!validateExer) {
        return;
      }
      let validateQues = this.validateQuestionByTypeForExercise(this.data_type_question);
      if (!validateQues) {
        return;
      }

      let vm = this;
      if (vm.toggle_element) {
        vm.is_call_api = true;
        let formData = new FormData();
        let valueElement = vm.getValueElement(vm.type_element);
        if (vm.type_element === "Video") {
          vm.element_id = vm.video_element_id;
          vm.is_call_api = false;
        } else if (this.type_element === "Album") {
          vm.element_id = vm.album_element_id;
          vm.is_call_api = false;
        } else if (this.type_element === "Flashcard") {
          let elementData = vm.element_flashcards.map((item) => {
            item.element_id = vm.element_id;
            item.example = !vm.$utils.isValueInvalid(item.example) ? item.example : null;
            return item;
          });
          formData.append("element_id", vm.element_id);
          formData.append("type", valueElement);
          formData.append("data_flashcard", JSON.stringify(elementData));
          await ApiService.post("prep-app/test/draft/exercise/upload-element", formData)
              .then(function (response) {
                vm.element_flashcards = response.data.flashcards;
                vm.is_call_api = false;
              })
              .catch(function (error) {
                if (error) {
                  vm.dialogExercise = false;
                  vm.is_call_api = false;
                  vm.messError("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ");
                }
              });
        } else {
          formData.append("element_id", vm.element_id);
          formData.append("type", valueElement);
          if (!this.checkIsElementTypeForUploadFileByTypeTxt(this.type_element)) {
            formData.append("editor", vm.checkElementData(vm.type_element));
          }
          formData.append("file", vm.checkElementFile(vm.type_element));
          formData.append("description", vm.element_description);
          if (this.type_element === "Audio") {
            if (!this.$utils.isValueInvalid(this.element_audio_file)) {
              formData.append("duration", await this.getAudioDurationFromFile(this.element_audio_file));
            } else {
              formData.append("duration", await this.getAudioDurationFromBase64OrUrl(this.element_audio_data));
            }
          }
          await ApiService.post("prep-app/test/draft/exercise/upload-element", formData)
            .then(function (response) {
              vm.element_id = response.data.element_code;
              vm.is_call_api = false;
            })
            .catch(function (error) {
              if (error) {
                vm.dialogExercise = false;
                vm.is_call_api = false;
                vm.messError("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ");
              }
            });
        }
      }

      // hanlde data exercise
      let totalQuestion = 0;
      let body;
      if (this.data_type_question === this.exercise_type_constant.MATCHING) {
        body = {
          option: this.optionMatching.map((item) => item.value),
          correct: this.correctMatching,
        };
        totalQuestion = this.correctMatching.length;
      } else if (this.data_type_question === this.exercise_type_constant.ANSWER_IN_ANY_ORDER) {
        body = this.optionListSelection;
        totalQuestion = this.optionListSelection.length;
      } else if (this.data_type_question === this.exercise_type_constant.SHORT_ANSWER) {
        body = this.correctShortAnswer;
        totalQuestion = (body.match(/{{(.*?)}}/g) || []).length;
      } else if (this.data_type_question === this.exercise_type_constant.SINGLE_CHOICE_IN_PARAGRAPH) {
        body = {
          content: this.contentSingleChoiceInParagraph,
          data_correct_option: this.dataCorrectSingleChoiceParagraph,
        };
        totalQuestion = this.dataCorrectSingleChoiceParagraph.length;
      } else if (this.data_type_question === this.exercise_type_constant.MATCHING_PAIR) {
        body = {
          question_type: this.matching_pair_question_type,
          questions: this.data_question_matching_pair_input,
          options: this.data_option_matching_pair_input,
          option_type: this.matching_pair_option_type,
          list_correct: this.data_correct_matching_pair,
        };
        totalQuestion = this.data_correct_matching_pair.length;
      }  else if (this.data_type_question === this.exercise_type_constant.MOCK_CONVERSATION) {
        body = {
          dataMockConversationQuestion: this.dataMockConversationQuestion,
        };
        totalQuestion = this.dataMockConversationQuestion.length;
      } else {
        body = null;
      }
      let data = {
        test_id: this.test_id,
        topic_id: this.topic_id,
        skill_id: this.skill_id,
        section_id: this.section_id,
        exercise_title: this.inputTitleExercise,
        exercise_description: this.inputDesExercise,
        exercise_type: this.data_type_question,
        element_type: this.type_element,
        element_data: this.checkElementData(this.type_element),
        element_id: this.toggle_element === false ? null : this.element_id,
        element_description: this.element_description  ?? null,
        audio_timer: null,
        is_element: this.toggle_element,
        total_question: totalQuestion,
        total_curly_braces: totalQuestion,
        validation_rules: this.checkSaveValidationRules(),
        offset: this.offsetSortExercise,
        is_use_suggestion_order: this.is_use_suggestion_order ? 1 : 0,
        suggestion_order_comment: null,
        interactive: this.toggle_interactive_video ? 1 : 0,
        body: body,
        narrator: this.narrator_input,
        narrator_audio_text: null,
        instant_feedback: this.has_config_instant_feedback ? 1 : 0,
        phonetic_display: this.has_phonetic_display ? 1 : 0,
        situation_code: this.subSituationCodeInput ?? null,
        related_unit_ids: this.listRelatedUnitIdInput.length ? this.listRelatedUnitIdInput : [],
        label_id: this.labelIdInput ?? null,
        sub_element_id: null,
        sub_element_data: null,
        sub_element_type: null,
      };
      if (this.explanationExercise !== "") {
        data.explanation = this.explanationExercise;
      }

      if (this.toggle_countdown_time) data.countdown_time = this.$options.filters.convertMSToSecond(this.countdown_time_input);

      if (this.is_use_suggestion_order)
        data.suggestion_order_comment = this.suggestion_order_comment;

      if (this.toggle_interactive_video) {
        data.interactive_config = {
          display_timestamp: this.display_timestamp_input,
          display_explanation: this.display_explanation_input,
          continue_lesson_timestamp: this.continue_lesson_timestamp_input,
          waiting_time: this.waiting_time_input,
          time_limit: this.time_limit_input,
          acceptable_score: this.acceptable_score_input,
        }
      }

      if (this.skillCurrent === 'Speaking' && this.skills[this.indexSkill].vsr === 1) {
        data.narrator_audio_text = this.narrator_input;
      }

      vm.is_call_api = true;
      if (
          [
            this.exercise_type_constant.MOCK_CONVERSATION,
          ].includes(this.data_type_question)
      ) {
        this.createOrUpdateWithFormData(data);
        return;
      }
      // if(this.is_call_api) {
      //   console.log(data);
      //   this.is_call_api = false;
      //   return;
      // }
      await ApiService.post("prep-app/test/draft/exercise", data)
        .then(function (res) {
          if (res.status === 200) {
            vm.handleAfterCreate(data, res);
          }
        })
        .catch(function (error) {
          if (error) {
            vm.dialogExercise = false;
            vm.is_call_api = false;
            vm.messError('Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ');
          }
        });
    },
    async btnEditExercise() {
      let validateExer = this.validateElementExercise();
      if (!validateExer) {
        return;
      }
      let validateQues = this.validateQuestionByTypeForExercise(
        this.data_type_question
      );
      if (!validateQues) {
        return;
      }

      let vm = this;
      //let element_id = null;
      if (vm.toggle_element) {
        vm.is_call_api = true;
        let formData = new FormData();
        let valueElement = vm.getValueElement(vm.type_element);
        if (vm.type_element === "Video") {
          vm.element_id = vm.video_element_id;
          // vm.video_element_id = null;
          vm.is_call_api = false;
        } else if (vm.type_element === "Album") {
          vm.element_id = vm.album_element_id;
          vm.is_call_api = false;
        } else if (this.type_element === "Flashcard") {
          let elementData = vm.element_flashcards.map((item) => {
            item.element_id = vm.element_id;
            item.example = !vm.$utils.isValueInvalid(item.example) ? item.example : null;
            return item;
          });
          formData.append("element_id", vm.element_id);
          formData.append("type", valueElement);
          formData.append("data_flashcard", JSON.stringify(elementData));
          await ApiService.post("prep-app/test/draft/exercise/upload-element", formData)
              .then(function (response) {
                vm.element_flashcards = response.data.flashcards;
                vm.is_call_api = false;
              })
              .catch(function (error) {
                if (error) {
                  vm.dialogExercise = false;
                  vm.is_call_api = false;
                  vm.messError("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ");
                }
              });
        } else {
          formData.append("element_id", vm.element_id);
          formData.append("type", valueElement);
          if (!this.checkIsElementTypeForUploadFileByTypeTxt(this.type_element)) {
            formData.append("editor", vm.checkElementData(vm.type_element));
          }
          formData.append("file", vm.checkElementFile(vm.type_element));
          formData.append("description", vm.element_description);
          if (this.type_element === "Audio") {
            if (!this.$utils.isValueInvalid(this.element_audio_file)) {
              formData.append("duration", await this.getAudioDurationFromFile(this.element_audio_file));
            } else {
              formData.append("duration", await this.getAudioDurationFromBase64OrUrl(this.element_audio_data));
            }
          }
          await ApiService.post("prep-app/test/draft/exercise/upload-element", formData)
            .then(function (response) {
              vm.element_id = response.data.element_code;
              vm.is_call_api = false;
            })
            .catch(function (error) {
              if (error) {
                vm.dialogExercise = false;
                vm.is_call_api = false;
                vm.messError("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              }
            });
        }
      }

      // hanlde data exercise
      let totalQuestion = 0;
      let body;
      if (this.data_type_question === this.exercise_type_constant.MATCHING) {
        body = {
          option: this.optionMatching.map((item) => item.value),
          correct: this.correctMatching,
        };
        totalQuestion = this.correctMatching.length;
      } else if (this.data_type_question === this.exercise_type_constant.ANSWER_IN_ANY_ORDER) {
        body = this.optionListSelection;
        totalQuestion = this.optionListSelection.length;
      } else if (this.data_type_question === this.exercise_type_constant.SHORT_ANSWER) {
        body = this.correctShortAnswer;
        totalQuestion = (body.match(/{{(.*?)}}/g) || []).length;
      } else if (this.data_type_question === this.exercise_type_constant.SINGLE_CHOICE_IN_PARAGRAPH) {
        body = {
          content: this.contentSingleChoiceInParagraph,
          data_correct_option: this.dataCorrectSingleChoiceParagraph,
        };
        totalQuestion = this.dataCorrectSingleChoiceParagraph.length;
      } else if (this.data_type_question === this.exercise_type_constant.MATCHING_PAIR) {
        body = {
          question_type: this.matching_pair_question_type,
          questions: this.data_question_matching_pair_input,
          options: this.data_option_matching_pair_input,
          option_type: this.matching_pair_option_type,
          list_correct: this.data_correct_matching_pair,
        };
        totalQuestion = this.data_correct_matching_pair.length;
      } else if (this.data_type_question === this.exercise_type_constant.MOCK_CONVERSATION) {
        body = {
          dataMockConversationQuestion: this.dataMockConversationQuestion,
        };
        totalQuestion = this.dataMockConversationQuestion.length;
      } else {
        body = null;
      }
      // let check_element_id = (element_id == null) ? vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].element_id : element_id;
      let data = {
        id: this.exercise_id,
        test_id: this.test_id,
        topic_id: this.topic_id,
        skill_id: this.skill_id,
        section_id: this.section_id,
        exercise_title: this.inputTitleExercise,
        exercise_description: this.inputDesExercise,
        exercise_type: this.data_type_question,
        element_type: this.type_element,
        element_data: this.checkElementData(this.type_element),
        element_id: this.toggle_element === false ? null : this.element_id,
        element_description: this.element_description  ?? null,
        is_element: this.toggle_element,
        validation_rules: this.checkSaveValidationRules(),
        body: body,
        is_use_suggestion_order: this.is_use_suggestion_order ? 1 : 0,
        suggestion_order_comment: this.is_use_suggestion_order ? this.suggestion_order_comment : null,
        interactive: this.toggle_interactive_video ? 1 : 0,
        narrator: this.narrator_input,
        questions: this.questions,
        instant_feedback: this.has_config_instant_feedback ? 1 : 0,
        phonetic_display: this.has_phonetic_display ? 1 : 0,
        totalQuestion: totalQuestion,
        situation_code: this.subSituationCodeInput ?? null,
        related_unit_ids: this.listRelatedUnitIdInput.length ? this.listRelatedUnitIdInput : [],
        label_id: this.labelIdInput ?? null,
      };
      if (this.explanationExercise !== "") {
        data.explanation = this.explanationExercise;
      }
      if (this.toggle_countdown_time) data.countdown_time = this.$options.filters.convertMSToSecond(this.countdown_time_input);
      if (this.toggle_interactive_video) {
        data.interactive_config = {
          display_timestamp: this.display_timestamp_input,
          display_explanation: this.display_explanation_input,
          continue_lesson_timestamp: this.continue_lesson_timestamp_input,
          waiting_time: this.waiting_time_input,
          time_limit: this.time_limit_input,
          acceptable_score: this.acceptable_score_input,
        }
      }
      vm.is_call_api = true;
      if (
          [
            this.exercise_type_constant.MOCK_CONVERSATION,
          ].includes(this.data_type_question)
      ) {
        this.createOrUpdateWithFormData(data);
        return;
      }
      await ApiService.put("prep-app/test/draft/exercise/" + this.exercise_id, data)
        .then(function (res) {
          if (res.status === 200) {
            vm.handleAfterUpdate(data, res);
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error) {
            vm.dialogExercise = false;
            vm.is_call_api = false;
            vm.messError('Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ');
          }
        });
    },
    checkSaveValidationRules() {
      let data = null;
      if (this.data_type_question === this.exercise_type_constant.SHORT_ANSWER) {
        data = {
          max_words: this.max_words,
        };
      }
      return data;
    },
    actionUpdateOffsetTotalQuestion(total_old, total_new) {
      let vm = this;
      vm.skills[vm.indexSkill].sections[vm.indexSection].total_question -= total_old;
      vm.skills[vm.indexSkill].sections[vm.indexSection].total_question += total_new;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].total_question -= total_old;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].total_question += total_new;
      for (let indexExer = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises.length - 1; indexExer >= vm.indexExercise + 1; indexExer--) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[indexExer].offset_question -= total_old;
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[indexExer].offset_question += total_new;
      }
      for (let indexSec = vm.skills[vm.indexSkill].sections.length - 1; indexSec >= vm.indexSection + 1; indexSec--) {
        vm.skills[vm.indexSkill].sections[indexSec].offset_question -= total_old;
        vm.skills[vm.indexSkill].sections[indexSec].offset_question += total_new;
      }
    },
    deleteListSelection(i) {
      if (this.optionListSelection.length <= 1) {
        this.messError('Phải có tối thiểu 1 question');
        return;
      }
      this.optionListSelection.splice(i, 1);
    },
    addOptionListSelection() {
      let data = {
        value: "",
        checked: false,
      };
      this.optionListSelection.push(data);
    },
    openDialogShortAnswer() {
      this.refShortAnswer = this.$refs.refShortAnswer;
      this.optionShortAnswer = [""];
      this.dialogShortAnswer = true;
    },
    validatorConfigInteractiveVideo() {
      let flat = false;
      if (!this.isTypeQuestionForInteractiveVideo(this.data_type_question) && !this.$utils.isValueInvalid(this.data_type_question)) {
        this.messError("Chỉ support cho 3 dạng: Matching, Multiple Choice, Single Choice");
        flat = true;
      }

      let section = this.skills[this.indexSkill].sections[this.indexSection];
      if (section.element_type !== 'Video') {
        this.$toasted.error("Chưa thêm video cho section. Hãy bổ sung trước khi tạo interactive exercise", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = true;
      } else {
        if (section.element_data === '') {
          this.$toasted.error("Hãy chọn video để dùng tính năng này", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = true;
        }
        if (section.sub_element_type === 'Video') {
          this.$toasted.error("Có nhiều hơn một video trong section", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = true;
        }
      }

      return flat;
    },
    isTypeQuestionForInteractiveVideo(type){
      return [
        this.exercise_type_constant.MATCHING,
        this.exercise_type_constant.MULTIPLE_CHOICE,
        this.exercise_type_constant.SINGLE_CHOICE,
      ].includes(type);
    },
    resetDataInteractiveVideo() {
      this.url_video_config = "";
      this.toggle_interactive_video = false;
      this.display_timestamp_input = "";
      this.display_explanation_input = "";
      this.continue_lesson_timestamp_input = "";
      this.waiting_time_input = "";
      this.time_limit_input = "";
      this.acceptable_score_input = "";
      for (let i = 0; i <this.dataExerciseType.length ; i++) {
        this.dataExerciseType[i].show = true;
      }
    },
    async setDisplayTypeExercise() {
      // set default display option type exercise
      let vm  = this;
      await this.resetDefaultOptionExType();

      let skill = this.skills[this.indexSkill];
      let section = skill.sections[this.indexSection];
      if (skill.skill_type === 'Writing' && skill.vwr === 1) {
        if (this.skills[this.indexSkill]?.sections[this.indexSection].partConfig) {
          let partConfig = section.partConfig;
          for (let i = 0; i < this.dataExerciseType.length ; i++) {
            this.dataExerciseType[i].show = false;
            if (canDisplayByCategoryToeicAndConfigPartForVwr(partConfig, this.dataExerciseType[i]) ) {
              this.dataExerciseType[i].show = true;
            }
            if (canDisplayByCategoryIeltsAndConfigPartForVwr(partConfig, this.dataExerciseType[i]) ) {
              this.dataExerciseType[i].show = true;
            }

          }
        }
      }

      if (skill.skill_type === 'Speaking' && skill.vsr === 1) {
        for (let i = 0; i < this.dataExerciseType.length ; i++) {
          if (this.dataExerciseType[i].value !== this.exercise_type_constant.RECORDING) {
            this.dataExerciseType[i].show = false;
          }
        }
      }

      function canDisplayByCategoryToeicAndConfigPartForVwr(partConfig = {}, objExerciseType = {}) {
        if (vm.product_category_id !== vm.product_category_const.TOEIC) return false;
        if (Object.keys(partConfig).length <= 0) return false;
        if (Object.keys(objExerciseType).length <= 0) return false;
        if(partConfig.module !== vm.module_section_part.VWR) return false;

        if(
            [vm.offset_section_part.PART_1, vm.offset_section_part.PART_2,].includes(partConfig.offset) &&
            [vm.exercise_type_constant.LONG_ANSWER ].includes(objExerciseType.value)
        ) {
          return true;
        }

        if(
            [vm.offset_section_part.PART_3].includes(partConfig.offset) &&
            [vm.exercise_type_constant.VIRTUAL_WRITING_ROOM].includes(objExerciseType.value)
        ) {
          return true;
        }

        return false;
      }

      function canDisplayByCategoryIeltsAndConfigPartForVwr(partConfig = {}, objExerciseType = {}) {
        if (vm.product_category_id !== vm.product_category_const.IELTS) return false;
        if (Object.keys(partConfig).length <= 0) return false;
        if (Object.keys(objExerciseType).length <= 0) return false;
        if(partConfig.module !== vm.module_section_part.VWR) return false;

        if(
            [vm.offset_section_part.PART_1].includes(partConfig.offset) &&
            [vm.exercise_type_constant.VIRTUAL_WRITING_ROOM_WT1_IELTS].includes(objExerciseType.value)
        ) {
          return true;
        }
        if(
            [vm.offset_section_part.PART_2].includes(partConfig.offset) &&
            [vm.exercise_type_constant.VIRTUAL_WRITING_ROOM].includes(objExerciseType.value)
        ) {
          return true;
        }

        return false;
      }
    },
    messError(mess = '') {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    defaultDataMatchingPair() {
      this.data_question_matching_pair_input = [{value: null, offset : 1, id: null}, {value: null, offset: 2 , id : null}];
      this.data_option_matching_pair_input = [{value: null, offset : 1, uuid: null}, {value: null, offset: 2 , uuid : null}];
      this.matching_pair_question_type = 1; // Loại Text
      this.matching_pair_option_type = 1; // Loại Text
      this.data_correct_matching_pair = [
        {question:{value: null, offset : 1 ,id : null}, option: {value: null, offset: null, uuid : null}},
        {question:{value: null, offset : 2, id : null}, option: {value: null, offset: null, uuid : null}},
      ];
    },
    defaultDataMockConversation() {
      this.dataMockConversationQuestion = [{question_id: null, offset: 1, character_id: null, script: "", translate_script: "", audio_data: null, audio_file: null,}];
    },
    isTypeQuestionForInstantFeedback(type){
      return [
        this.exercise_type_constant.MULTIPLE_CHOICE,
        this.exercise_type_constant.SINGLE_CHOICE,
        this.exercise_type_constant.SINGLE_CHOICE_EDITOR,
        this.exercise_type_constant.HSK_SENTENCE_SPEECH_ASSESSMENT,
        this.exercise_type_constant.UNIVERSAL,
      ].includes(type);
    },
    resetDefaultOptionExType() {
      for (let i = 0; i < this.dataExerciseType.length ; i++) {
        this.dataExerciseType[i].show = true;
      }
    },
    canToggleConfigInstantFeedback() {
      let flat = false;
      if (!this.isTypeQuestionForInstantFeedback(this.data_type_question) && !this.$utils.isValueInvalid(this.data_type_question)) {
        let arrLabelQuestionForInstantFeedback  = [];
        for (let i = 0; i < this.exercise_types.length ; i++) {
          if (this.isTypeQuestionForInstantFeedback(this.exercise_types[i].value)) {
            arrLabelQuestionForInstantFeedback.push(this.exercise_types[i].label);
          }
        }
        let slipLabelQuestionForInstantFeedback = arrLabelQuestionForInstantFeedback.join(", ");
        this.messError("Chỉ support cho "+arrLabelQuestionForInstantFeedback.length+" dạng: "+slipLabelQuestionForInstantFeedback);
        flat = true;
      }
      return flat;
    },
    canOpenCreateDialogExercise() {
      let flat = true;

      let section = this.skills[this.indexSkill].sections[this.indexSection];

      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        if(this.type_expand === this.test_type_expand_constant.LESSON) {
          if (section.partConfig) {
            let partConfig = section.partConfig;
            if (
                partConfig.module === this.section_config_module_constant.PREP_TALK_MODULE &&
                partConfig.product_category_id === this.product_category_constant.PREPTALK_ENGLISH
            ) {
              if (partConfig.offset === this.section_offset_constant.OFFSET_1) {
                this.messError("Không thể tạo exercise trong section: "+ partConfig.part_name);
                flat = false;
              }
            }

          }
        }
        if(this.type_expand === this.test_type_expand_constant.TEST) {
          if (section.exercises.length >= 1) {
            this.messError("Chỉ tạo 1 exercise cho section thuộc test PTE");
            flat = false;
          }
        }
      }

      return flat;
    },
    createOrUpdateWithFormData(data) {
      let vm = this;
      let formData = new FormData();
      if (data.id) {
        formData.append("_method", "PUT");
        formData.append("id", data.id);
      }
      formData.append("skill_id", data.skill_id);
      formData.append("section_id", data.section_id);
      formData.append("exercise_title", data.exercise_title);
      formData.append("exercise_description", data.exercise_description);
      formData.append("exercise_type", data.exercise_type);
      formData.append("element_type", data.element_type);
      formData.append("element_data", data.element_data);
      formData.append("total_question", data.total_question);
      formData.append("total_curly_braces", data.total_curly_braces);
      formData.append("validation_rules", data.validation_rules);
      formData.append("offset", data.offset);
      if (data.body) formData.append("body", JSON.stringify(data.body));
      if (data.element_id) formData.append("element_id", data.element_id);
      if (data.element_description) formData.append("element_description", data.element_description);
      if (data.element_type) formData.append("element_type", data.element_type);
      if (data.element_data) formData.append("element_data", data.element_data);
      if (data.is_element) formData.append("is_element", data.is_element);
      if (data.explanation) formData.append("explanation", data.explanation);
      if (data.exercise_type === this.exercise_type_constant.MOCK_CONVERSATION) {
        for (let i = 0; i < this.dataMockConversationQuestion.length; i++) {
          formData.append('question_audio_file_' + (i + 1), this.dataMockConversationQuestion[i].audio_file);
        }
      }
      if (!data.id) {
        ApiService.post("prep-app/test/draft/exercise", formData)
        .then(function (res) {
          if (res.status === 200) {
            vm.is_call_api = false;
            vm.handleAfterCreate(data, res);
          }
        })
        .catch(function (error) {
          if (error) {
            // vm.dialogExercise = false;
            vm.is_call_api = false;
            vm.messError('Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ');
          }
        });
      } else {
        ApiService.post("prep-app/test/draft/exercise/" + data.id, formData)
        .then(function (res) {
          if (res.status === 200) {
            vm.is_call_api = false;
            vm.handleAfterUpdate(data, res);
          }
        })
        .catch(function (error) {
          if (error) {
            // vm.dialogExercise = false;
            vm.is_call_api = false;
            vm.messError('Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ');
          }
        });
      }
    },
    handleAfterCreate(data, res) {
      let vm = this;
      let checkExercise = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises;
      if (checkExercise === undefined || checkExercise == null) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises = [];
      }
      data.id = res.data.id;
      data.questions = res.data.questions;
      if (
          [vm.exercise_type_constant.MATCHING_PAIR, vm.exercise_type_constant.MOCK_CONVERSATION].includes(vm.data_type_question)
      ) {
        data.body = JSON.parse(res.data.body);
      }
      let indexExer = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises.push(data) - 1;
      if (indexExer === 0) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[indexExer].offset_question = 0;
      } else {
        let offset = 0;
        for (let x = 0; x < indexExer; x++) offset += vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[x].total_question;
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[indexExer].offset_question = offset;
      }
      for (let iSec = vm.skills[vm.indexSkill].sections.length - 1; iSec >= vm.indexSection + 1; iSec--) {
        vm.skills[vm.indexSkill].sections[iSec].offset_question += data.total_question;
      }
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[indexExer].total_question = data.total_question;
      vm.skills[vm.indexSkill].sections[vm.indexSection].total_question += data.total_question;
      vm.$set(vm.skills);
      // vm.$store.commit('contentTestBuilderStore/addExercise', data);
      vm.is_call_api = false;
      vm.dialogExercise = false;
      setTimeout(() => {
        vm.$scrollTo("#exercise" + vm.indexSkill + vm.indexSection + indexExer, 1000);
      }, 100);
    },
    handleAfterUpdate(data, res) {
      let vm = this;
      let body = data.body;
      if (vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].exercise_type !== vm.data_type_question) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions = [];
      } else {
        if (vm.data_type_question === vm.exercise_type_constant.MATCHING) {
          vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions = res.data.questions;
          let total_old = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].body.correct.length;
          vm.actionUpdateOffsetTotalQuestion(total_old, data.totalQuestion);
        } else if (vm.data_type_question === vm.exercise_type_constant.ANSWER_IN_ANY_ORDER) {
          vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions = res.data.questions;
          let total_old = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].body.length;
          vm.actionUpdateOffsetTotalQuestion(total_old, data.totalQuestion);
        } else if (vm.data_type_question === vm.exercise_type_constant.SHORT_ANSWER) {
          vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions = res.data.questions;
          let total_old = (vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].body.match(/{{(.*?)}}/g) || []).length;
          vm.actionUpdateOffsetTotalQuestion(total_old, data.totalQuestion);
        } else if ([vm.exercise_type_constant.MATCHING_PAIR].includes(vm.data_type_question)) {
          body = JSON.parse(res.data.body);
          vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions = res.data.questions;
          let total_old = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].body.questions.length;
          vm.actionUpdateOffsetTotalQuestion(total_old, data.totalQuestion);
        } else if ([vm.exercise_type_constant.MOCK_CONVERSATION].includes(vm.data_type_question)) {
          body = JSON.parse(res.data.body);
          let total_old = vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions.length;
          vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].questions = res.data.questions;
          vm.actionUpdateOffsetTotalQuestion(total_old, data.totalQuestion);
        }
      }
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].exercise_title = vm.inputTitleExercise;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].exercise_description = vm.inputDesExercise;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].narrator = vm.narrator_input;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].exercise_type = vm.data_type_question;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].element_type = vm.type_element;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].element_data = vm.toggle_element === false ? null : vm.checkElementData(vm.type_element);
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].element_id = vm.toggle_element === false ? null : vm.element_id;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].element_description = vm.element_description;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].is_element = vm.toggle_element;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].total_curly_braces = data.totalQuestion;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].validation_rules = vm.checkSaveValidationRules();
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].body = body;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].explanation = vm.explanationExercise;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].countdown_time = vm.toggle_countdown_time ? vm.$options.filters.convertMSToSecond(vm.countdown_time_input) : null;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].is_use_suggestion_order = vm.is_use_suggestion_order ? 1 : 0;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].suggestion_order_comment = vm.is_use_suggestion_order ? vm.suggestion_order_comment : null;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].interactive = vm.toggle_interactive_video ? 1 : 0;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].instant_feedback = vm.has_config_instant_feedback ? 1 : 0;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].phonetic_display = vm.has_phonetic_display ? 1 : 0;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].situation_code = vm.subSituationCodeInput ?? null;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].related_unit_ids = vm.listRelatedUnitIdInput.length ? vm.listRelatedUnitIdInput : [];
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].label_id = vm.labelIdInput ?? null;
      if (vm.toggle_interactive_video) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[vm.indexExercise].interactive_config = data.interactive_config;
      }
      vm.$set(vm.skills);
      vm.is_call_api = false;
      vm.dialogExercise = false;
    },
    canInputExplanation() {
      let flat = false;
      if ([
            this.exercise_type_constant.MATCHING,
            this.exercise_type_constant.ANSWER_IN_ANY_ORDER,
            this.exercise_type_constant.SHORT_ANSWER,
            this.exercise_type_constant.MATCHING_PAIR,
            this.exercise_type_constant.MOCK_CONVERSATION,
          ].includes(this.data_type_question)
      ) {
        flat = true;
      }
        return flat;
    },
    getRelatedUnitsByTopic() {
      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        if (this.type_expand === this.test_type_expand_constant.TEST) {
          if (this.data_type_question === this.exercise_type_constant.VIRTUAL_CONVERSATION) {
            let vm = this;
            ApiService.get("prep-app/courses/"+vm.topic_id+"/items/lesson?")
            .then(function (res) {
              if (res.status === 200) {
                vm.relatedUnits = res.data;
              }
            })
            .catch(function (error) {
              if (error) {
                vm.messError('Không lấy được danh sách unit cần học');
                vm.relatedUnits = [];
              }
            });
            return;
          }

        }
      }

      this.relatedUnits = [];
    },
    canInputNarrator() {
      let flat = false;
      if (this.product_category_id === this.product_category_constant.TOEIC) {
        if (this.skillCurrent === 'Speaking' && this.skills[this.indexSkill].vsr === 1) {
          flat = true;
        }
      }
      return flat;
    },
    canConfigInputPinyin() {
      let flat = false;
      if (this.product_category_id === this.product_category_constant.HSK) {
        flat = true;
      }
      return flat;
    },
    canToggleConfigInputPinyin() {
      let flat = false;
      if (
          !this.listExerciseTypeCanConfigPinyin().includes(this.data_type_question) &&
          !this.$utils.isValueInvalid(this.data_type_question)
      ) {
        let arrLabelQuestionForPinyin  = [];
        for (let i = 0; i < this.exercise_types.length ; i++) {
          if (this.listExerciseTypeCanConfigPinyin().includes(this.exercise_types[i].value)) {
            arrLabelQuestionForPinyin.push(this.exercise_types[i].label);
          }
        }
        let slipLabelQuestionForPinyin = arrLabelQuestionForPinyin.join(", ");
        this.messError("Chỉ support cho "+arrLabelQuestionForPinyin.length+" dạng: "+slipLabelQuestionForPinyin);
        flat = true;
      }
      return flat;
    },
    allowedToUsePhoneticForExplanation() {
      let flat = false;
      if (this.product_category_id === this.product_category_constant.HSK) {
        if (this.has_phonetic_display) {
          flat = true;
        }
      }
      return flat;
    },
    canInputRelatedUnitId() {
      let flat = false;
      if (this.product_category_id === this.product_category_constant.PREPTALK_ENGLISH) {
        flat = true;
      }
      return flat;
    },
    getExerciseTypeNote() {
      if (this.data_type_question === this.exercise_type_constant.MOCK_CONVERSATION) {
        return "Trong một Section, KHÔNG thể kết hợp dạng bài tập này với những dạng bài tập khác";
      }
      return '';
    }
  },
};
</script>

<style lang="css" scoped>
.btn-add-file {
  background-color: #05cdff !important;
  color: white !important;
}
</style>
